import {IShoePart, ITexture} from "../../../../interface";
import './_option-colors.scss';
import {VStore} from "../../../../store";
import Puzzles from "../../../../utils/puzzles";

interface IOptionButtonProps {
    part: IShoePart;
    name: string;
    textureIds: string[];
}

export const OptionColors = ({name, textureIds, part}: IOptionButtonProps) => {
    const {shoe, setShoe, currentSide, textures, materials} = VStore();

    const filteredTextures = textureIds.map((id) => {
        return textures[id]
    })

    // @ts-ignore
    const _currentValue = shoe[currentSide][part];

    function selectOption(texture: ITexture) {
        const _shoe = JSON.parse(JSON.stringify(shoe));
        // @ts-ignore
        _shoe[currentSide][part] = texture.id;
        setShoe(_shoe);

        const material = materials[texture.materialId];

        if (material) {
            const _part = currentSide === 'left' ? part + '.left' : part;

            if (material.type === 'mirror') {
                Puzzles.procedures?.setGlossyLeather(_part, texture.color!.toLowerCase());
            } else if (material.type === 'metallic') {
                Puzzles.procedures?.setMetallicLeather(_part, texture.color!.toLowerCase());
            } else if (material.type === 'matt') {
                Puzzles.procedures?.setMattLeather(_part, texture.color!.toLowerCase());
            }
        }
    }

    return <div className='option-colors w-100 d-flex flex-column'>
        <div className='w-100 d-flex align-items-center'>
            <span className='option-color__name'>{name}</span>
            <hr/>
        </div>
        <div className='w-100 d-flex flex-nowrap flex-md-wrap overflow-auto' style={{maxHeight: '250px'}}>
            {
                filteredTextures.map((texture, index) => {
                    return <button
                        key={texture.id}
                        className={_currentValue === texture.id ? 'active' : ''}
                        onClick={() => selectOption(texture)}
                    >
                        <img src={texture.thumbnail} alt={texture.id}/>
                    </button>
                })
            }
        </div>
    </div>
}
