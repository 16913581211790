import './_controller.scss';
import {MainButton} from "./main-buttons/main-button";
import {PartItem} from "./part-item/part-item";
import {useEffect} from "react";
import {OptionList} from "./options-list/options-list";
import CustomDesign from "./custom-design/custom-design";
import PairOption from "./pair-option/pair-option";
import {VStore} from "../../store";
import {IData, IMaterials, ITextures} from "../../interface";
import {FirebaseUtils} from "../../utils/firebaseUtils";

export const Controller = () => {
    const {data, setData, currentSide} = VStore();

    function callbackSetData(snapshot: { data: IData, textures: ITextures, materials: IMaterials }): void {
        if (snapshot) {
            setData(snapshot);
        }
    }

    useEffect(() => {
        FirebaseUtils.refData(callbackSetData);
    }, []);

    return (
        <div className='controller col-12 col-md-5 col-lg-4 col-xl-3 d-flex flex-column flex-sm-row'>
            <div className='parts col-12 col-sm-4 col-md-3 d-flex flex-row flex-sm-column align-items-center m-md-1'>
                {
                    Object.values(data).map((item, index) => {
                        return <PartItem key={item.name} name={item.name} image={item.thumbnail} target={item.target}/>
                    })
                }
                <div className='mt-2 mb-2'>
                    <span style={{color: 'red', fontWeight: 'bold'}}>DESIGN</span>
                </div>
                <CustomDesign/>
            </div>
            <div className='col-12 col-sm-8 col-md-9 d-flex flex-column m-md-1 p-2'>
                <div className='flex-grow-1 d-flex flex-column'>
                    {
                        currentSide !== 'pair' ? <OptionList/> : <PairOption/>
                    }
                </div>
                <MainButton/>
            </div>
        </div>
    )
}
