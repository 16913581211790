import React, {useEffect} from 'react';
import ColorPalette from "../color-palette/color-palette";
import {VStore} from "../../../../store";
import puzzles from "../../../../utils/puzzles";

export default function LogoBacktop() {
    const {shoe, currentSide, currentPart, setShoe} = VStore();

    const [logoBacktopColor, setLogoBacktopColor] = React.useState<string>('');

    useEffect(() => {
        if (currentSide !== 'pair' && currentPart === 'logo_backtop') {
            setLogoBacktopColor(shoe[currentSide].logo_backtop);
        }
    }, [currentSide, currentPart])

    useEffect(() => {
        const _shoe = JSON.parse(JSON.stringify(shoe));
        _shoe[currentSide].logo_backtop = logoBacktopColor;
        setShoe(_shoe);
        puzzles.procedures?.changeBacktopLogoColor(logoBacktopColor, currentSide);
    }, [logoBacktopColor])


    return (<div className="logo-strap w-100 d-flex flex-column">
        {
            currentSide !== 'pair' ? <>
                <div className='w-100 d-flex align-items-center'>
                    <span className='logo-strap__name'>LOGO BACKTOP</span>
                    <hr/>
                </div>
                <ColorPalette _currentValue={logoBacktopColor}
                              onSelect={(color) => {
                                  setLogoBacktopColor(color);
                              }}
                />
            </> : null
        }
    </div>);
}
