import React from 'react';
import './App.css';
import {Category, LoginModal, MenuBar, RegisterModal, ShoeType} from "./compenents";
import {HashRouter, Routes, Route} from "react-router-dom";
import Customize from "./pages/customize/customize";

function App() {

    return (
        <HashRouter>
            <div className="app d-flex flex-column align-items-center">
                <MenuBar/>
                <Routes>
                    <Route path='/' element={<Category/>}/>
                    <Route path='/category' element={<Category/>}/>
                    <Route path='/customize' element={<Customize/>}/>
                </Routes>
                <LoginModal/>
                <RegisterModal/>
            </div>
        </HashRouter>

    );
}

export default App;
