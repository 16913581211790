import './_part-item.scss';
import {VStore} from "../../../store";
import puzzles from "../../../utils/puzzles";
import {IShoePart} from "../../../interface";

interface IPartItemProps {
    name: string;
    image: string;
    target: IShoePart;
}
export const PartItem = ({name, image, target}: IPartItemProps) => {
    const {currentPart, setCurrentPart, currentSide} = VStore();

    return <div className={'part-item d-flex flex-column align-items-center' + (currentSide === 'pair' ? ' disabled' : (currentPart !== target ? '' : ' active'))}
                onClick={() => {
                    setCurrentPart(target)
                    puzzles.procedures?.setCurrentObject(currentSide === 'left' ?  target + '.left' : target, false)
                }}
    >
        <img src={image} alt={name}/>
        <span>{name}</span>
    </div>
}
