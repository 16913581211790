import React from 'react';
import './_pair-option.scss';
import {Button, Form} from "react-bootstrap";
import {VStore} from "../../../store";
import {SIZE} from "../../../utils/constant";


export default function PairOption() {
    const {shoe, setShoe} = VStore();

    const currentSize = shoe.size;

    const mount = shoe.mount;

    return (<div className='w-100 h-100 pair-option'>
            <div className="options flex-column">
                <span className='options__name'>SIZE</span>
                <div className='size-btn-list'>
                    {
                        SIZE.map((size, index) => {
                            return (
                                <Button key={index} size='sm' variant={currentSize === size ? 'dark' : 'outline-dark'}
                                        className={'mb-2 me-2' + (size === 'Custom' ? ' col-3' : ' col-2')}
                                        onClick={() => setShoe({...shoe, size})}
                                >
                                    {size}
                                </Button>
                            )
                        })
                    }
                </div>
            </div>
            <div className='options flex-column'>
                <span className='options__name'>MOUNT</span>
                <Form className='d-flex option-mount'>
                    <Form.Group className='d-flex align-items-center me-3'>
                        <Form.Check id='mount_165' type='radio' name='mount' value='165' checked={mount === '165'}
                                    onChange={() => setShoe({...shoe, mount: '165'})}
                        ></Form.Check>
                        <Form.Label htmlFor='mount_165'>
                            <img src="./assets/images/icons/mount_165.png" alt="165"/>
                        </Form.Label>
                    </Form.Group>
                    <Form.Group className='d-flex align-items-center'>
                        <Form.Check id='mount_195' type='radio' name='mount' value='195' checked={mount === '195'}
                                    onChange={() => setShoe({...shoe, mount: '195'})}
                        ></Form.Check>
                        <Form.Label htmlFor='mount_195'>
                            <img src="./assets/images/icons/mount_195.png" alt="165"/>
                        </Form.Label>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
}
