import React from 'react';
import {IColor} from "../../../../interface";
import './_color-palette.scss';

export type ColorPaletteProps = {
    _currentValue: string;
    onSelect: (color: string) => void;
}

const COLORS: IColor[] = [
    {"name": "Dark Red", "color": 'rgb(115, 0, 2)'},
    {"name": "Red", "color": 'rgb(255, 0, 0)'},
    {"name": "Magenta", "color": 'rgb(255, 0, 255)'},
    {"name": "Orange", "color": 'rgb(255, 102, 0)'},
    {"name": "Yellow", "color": 'rgb(255, 255, 0)'},
    {"name": "Lemon yellow", "color": 'rgb(153, 255, 0)'},
    {"name": "Navi", "color": 'rgb(51, 0, 77)'},
    {"name": "Cyan", "color": 'rgb(0, 255, 255)'},
    {"name": "Moss", "color": 'rgb(13, 20, 13)'},
    {"name": "Dark Green", "color": 'rgb(177, 124, 0)'},
    {"name": "Gray", "color": 'rgb(128, 128, 128)'},
    {"name": "Beige", "color": 'rgb(255, 255, 230)'},
    {"name": "White", "color": 'rgb(255, 255, 255)'}
]


export default function ColorPalette({_currentValue, onSelect}: ColorPaletteProps) {
    return (<div className='color-palette w-100 d-flex flex-nowrap flex-md-wrap overflow-auto' style={{maxHeight: '250px'}}>
        {
            COLORS.map((color, index) => {
                return <button
                    key={color.color}
                    className={'color-btn ' + (_currentValue === color.color ? 'active' : '')}
                    onClick={() => onSelect(color.color)}
                >
                    <span className='w-100 h-100 d-flex'
                          style={{backgroundColor: color.color}}
                    >

                    </span>
                </button>
            })
        }
        <div className='d-flex align-items-center w-100'>
            <label htmlFor="input-color">More Color: </label>
            <input id='input-color' type="color" className='w-100 ms-1'
                     onChange={(e) => onSelect(e.target.value)}
            />
        </div>
    </div>);
}
