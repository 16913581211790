import './_category.scss';
import {VStore} from "../../store";
import {Link} from "react-router-dom";

export default function Category() {
    const {shoeType} = VStore();


    const url = `./assets/images/category/${shoeType}.jpg`
    return (
        <div className="category h-100 w-100 d-flex flex-column flex-md-row justify-content-around align-items-center">
            <div className='col-12 col-md-4'>
                <Link to='/customize'>
                    <img src={url} alt=""/>
                    <span><b>Inline</b></span>
                </Link>
            </div>
            <div className='col-12 col-md-4 disabled'>
                <Link to='/catergory'>
                    <img src={url} alt=""/>
                    <span><b>Short</b></span>
                </Link>

            </div>
            <div className='col-12 col-md-4 disabled'>
                <Link to='/catergory'>
                    <img src={url} alt=""/>
                    <span><b>Long</b></span>
                </Link>
            </div>
        </div>);
}
