import React from 'react';
import './_custom-design.scss';
import puzzles from "../../../utils/puzzles";
import {VStore} from "../../../store";

export default function CustomDesign() {
    const {currentPart, setCurrentPart, currentSide} = VStore();

    return (<>
        <div
            className={'part-item d-flex flex-column align-items-center' + (currentSide === 'pair' ? ' disabled' : (currentPart !== 'logo_strap' ? '' : ' active'))}
            onClick={() => {
                setCurrentPart('logo_strap')
                puzzles.procedures?.setCurrentObject(currentSide === 'left' ? 'strap.left' : 'strap', false)
            }}
        >
            <img src='./assets/images/icons/strap_text.png' alt='Logo Strap'/>
            <span>Logo Strap</span>
        </div>
        <div
            className={'part-item d-flex flex-column align-items-center' + (currentSide === 'pair' ? ' disabled' : (currentPart !== 'logo_quarter' ? '' : ' active'))}
            onClick={() => {
                setCurrentPart('logo_quarter')
                puzzles.procedures?.setCurrentObject(currentSide === 'left' ? 'quarter1.left' : 'quarter1', false)
            }}
        >
            <img src='./assets/images/icons/quarter1_text.png' alt='Logo Quarter'/>
            <span>Logo Quarter</span>
        </div>
        <div
            className={'part-item d-flex flex-column align-items-center' + (currentSide === 'pair' ? ' disabled' : (currentPart !== 'logo_backtop' ? '' : ' active'))}
            onClick={() => {
                setCurrentPart('logo_backtop')
                puzzles.procedures?.setCurrentObject(currentSide === 'left' ? 'back_top.left' : 'back_top', false)
            }}
        >
            <img src='./assets/images/icons/back_top_text.png' alt='Logo Backtop'/>
            <span>Logo Backtop</span>
        </div>
    </>);
}
