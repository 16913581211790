import React, {useState} from "react";
import {VStore} from "../../../store";
import puzzles from "../../../utils/puzzles";
import {Button, Modal} from "react-bootstrap";

export const MainButton = () => {
    const {
        setCurrentSide,
        isFirstSwitch,
        setIsFirstSwitch,
        shoe,
        setShoe,
        textures,
        materials,
        currentSide,
        setIsShowSummary,
        currentUser,
        setIsShowLogin,
    } = VStore();
    const [showRequireLogin, setShowRequireLogin] = useState(false);

    const handleChangeSide = (side: 'left' | 'right' | 'pair') => {
        if ((side !== 'right') && isFirstSwitch) {
            setIsFirstSwitch(false);
            const _shoe = JSON.parse(JSON.stringify(shoe));
            _shoe.left = {
                ..._shoe.right
            };
            Object.entries(_shoe.left).forEach(([key, value]) => {
                if (key === 'logo_strap') {
                    puzzles.procedures?.changeStrapLogoColor(_shoe.left.logo_strap, 'left')
                } else if (key === 'logo_quarter') {
                    const {text, color, font} = _shoe.left.logo_quarter;
                    puzzles.procedures?.changeCustomName(text, font, 'left')
                    puzzles.procedures?.changeCustomNameColor(color, 'left')
                } else if (key === 'logo_backtop') {
                    puzzles.procedures?.changeBacktopLogoColor(_shoe.left.logo_backtop, 'left')
                } else {
                    //@ts-ignore
                    const texture = textures[value];
                    if (texture) {
                        const material = materials[texture.materialId];
                        if (material) {
                            if (material.type === 'mirror') {
                                puzzles.procedures?.setGlossyLeather(key + '.left', texture.color!)
                            } else if (material.type === 'metallic') {
                                puzzles.procedures?.setMetallicLeather(key + '.left', texture.color!)
                            } else if (material.type === 'matt') {
                                puzzles.procedures?.setMattLeather(key + '.left', texture.color!)
                            }
                        }
                    }
                }
            })
            setShoe(_shoe);
        }

        if (side === 'left') {
            puzzles.procedures?.toggleLeft(true);
            puzzles.procedures?.toggleRight(false);
        } else if (side === 'right') {
            puzzles.procedures?.toggleRight(true);
            puzzles.procedures?.toggleLeft(false);
        } else if (side === 'pair') {
            puzzles.procedures?.showPair();
        }
        setCurrentSide(side);
    }

    function handleAddToCart() {
        if (!currentUser) {
            setShowRequireLogin(true);
            return;
        }
        setIsShowSummary(true);
    }

    return <div className='d-flex flex-wrap'>
        <div className='col-4 col-sm-12 d-flex flex-wrap'>
            <div className='col-12 col-sm-6 p-1'>
                <Button variant={currentSide === 'left' ? 'danger' : 'outline-danger'}
                        className='w-100 h-100' size='sm'
                        onClick={() => handleChangeSide('left')}
                >LEFT</Button>
            </div>
            <div className='col-12 col-sm-6 p-1'>
                <Button variant={currentSide === 'right' ? 'danger' : 'outline-danger'}
                        className='w-100 h-100'
                        size='sm'
                        onClick={() => handleChangeSide('right')}
                >RIGHT</Button>
            </div>
        </div>
        <div className='col-4 col-sm-12 p-1'>
            <Button variant={currentSide === 'pair' ? 'danger' : 'outline-danger'}
                    className='w-100 h-100' size='sm'
                    onClick={() => handleChangeSide('pair')}
            >PAIR</Button>
        </div>
        <div className='col-4 col-sm-12 p-1'>
            <Button variant='success' className='w-100 h-100' size='sm'
                    onClick={handleAddToCart}
                    disabled={currentSide !== 'pair'}
            >ADD TO CART</Button>
        </div>

        <Modal show={showRequireLogin}
               onHide={() => setShowRequireLogin(false)}
               size='sm'
               centered
        >
            <Modal.Header closeButton={true}></Modal.Header>
            <Modal.Body>
                <span>Please login before adding to cart</span>
            </Modal.Body>
            <Modal.Footer>
                <Button size='sm' variant='success' onClick={() => {
                    setShowRequireLogin(false);
                    setIsShowLogin(true);
                }}>Login</Button>
                <Button size='sm' variant='secondary' onClick={() => setShowRequireLogin(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    </div>
}
