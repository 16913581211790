import React, {FormEvent, useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {VStore} from "../../store";
import './_register-modal.scss';
import {FirebaseUtils} from "../../utils/firebaseUtils";
import {COUNTRY_LIST} from "../../utils/constant";

export default function RegisterModal() {
    const {isShowRegister, setIsShowRegister} = VStore();

    const [input, setInput] = useState({
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        country: '',
        address: '',
        city: '',
        zipCode: '',
        company: ''
    });

    /*const [input, setInput] = useState({
        email: 'hieuhuynh1407@gmail.com',
        phoneNumber: '123',
        password: '123456',
        confirmPassword: '123456',
        firstName: 'ABC',
        lastName: 'ABC',
        country: 'Vietnam',
        address: 'ABC',
        city: 'ABC',
        zipCode: 'ABC',
        company: 'ABC'
    });*/

    const [error, setError] = useState('');

    function handleClose() {
        setIsShowRegister(false);
    }

    async function onRegister(e: FormEvent) {
        e.preventDefault();
        if (input.password !== input.confirmPassword) {
            setError('Password and Confirm Password do not match');
            return;
        }
        try {
            const user = await FirebaseUtils.createUser(input.email, input.password);
            await FirebaseUtils.setUserData(user.user.uid, {
                email: input.email,
                phoneNumber: input.phoneNumber,
                firstName: input.firstName,
                lastName: input.lastName,
                country: input.country,
                address: input.address,
                city: input.city,
                zipCode: input.zipCode,
                company: input.company
            });
            setIsShowRegister(false);
        } catch (e: any) {
            if (e.code === 'auth/email-already-in-use') {
                setError('Email already in use');
            } else {
                setError(e.message);
            }
        }
    }

    return <Modal show={isShowRegister}
                  onHide={handleClose}
                  backdrop='static'
                  keyboard={false}
                  centered={true}
                  className='login-modal'
                  size={'lg'}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={onRegister}>
                <Form.Group className='d-flex mt-1'>
                    <Form.Group className='col-6 pe-2 required'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='email' placeholder='Enter email' required={true}
                                      onChange={(e) => setInput({...input, email: e.target.value})} value={input.email}
                        />
                    </Form.Group>
                    <Form.Group className='col-6 ps-2 required'>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type='text' placeholder='Enter phone number' required={true}
                                      onChange={(e) => setInput({...input, phoneNumber: e.target.value})}
                                      value={input.phoneNumber}
                        ></Form.Control>
                    </Form.Group>
                </Form.Group>
                <Form.Group className='d-flex mt-1'>
                    <Form.Group className='col-6 pe-2 required'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type='password' placeholder='Enter password' required={true}
                                      onChange={(e) => setInput({...input, password: e.target.value})}
                                      value={input.password}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className='col-6 ps-2 required'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type='password' placeholder='Confirm password' required={true}
                                      onChange={(e) => setInput({...input, confirmPassword: e.target.value})}
                                      value={input.confirmPassword}
                        ></Form.Control>
                    </Form.Group>
                </Form.Group>
                <Form.Group className='d-flex mt-1'>
                    <Form.Group className='col-6 pe-2'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type='text' placeholder='Enter first name' required={true}
                                      onChange={(e) => setInput({...input, firstName: e.target.value})}
                                      value={input.firstName}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className='col-6 ps-2'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type='text' placeholder='Enter last name' required={true}
                                      onChange={(e) => setInput({...input, lastName: e.target.value})}
                                      value={input.lastName}
                        ></Form.Control>
                    </Form.Group>
                </Form.Group>
                <Form.Group className='d-flex mt-1'>
                    <Form.Group className='col-6 pe-2'>
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                                setInput({...input, country: e.target.value});
                            }}
                            value={input.country}
                        >
                            {
                                COUNTRY_LIST.map((country) => {
                                    return <option key={country.name}
                                                   value={country.name}>{country.name}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='col-6 ps-2'>
                        <Form.Label>Address</Form.Label>
                        <Form.Control type='text' placeholder='Enter address' required={true}
                                      onChange={(e) => setInput({...input, address: e.target.value})}
                                      value={input.address}
                        ></Form.Control>
                    </Form.Group>
                </Form.Group>
                <Form.Group className='d-flex mt-1'>
                    <Form.Group className='col-6 pe-2'>
                        <Form.Label>City</Form.Label>
                        <Form.Control type='text' placeholder='Enter city' required={true}
                                      onChange={(e) => setInput({...input, city: e.target.value})} value={input.city}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className='col-6 ps-2'>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type='text' placeholder='Enter zip code' required={true}
                                      onChange={(e) => setInput({...input, zipCode: e.target.value})}
                                      value={input.zipCode}
                        ></Form.Control>
                    </Form.Group>
                </Form.Group>
                <Form.Group className='mt-1'>
                    <Form.Label>Company</Form.Label>
                    <Form.Control type='text' placeholder='Enter your Company'
                                  onChange={(e) => setInput({...input, company: e.target.value})} value={input.company}
                    ></Form.Control>
                </Form.Group>

                {
                    error && <Form.Group className='mt-3'>
                        <span className='text-danger'>{error}</span>
                    </Form.Group>
                }

                <div className='w-100 mt-3 d-flex justify-content-between'>
                    <Form.Group>
                        <Button variant='success' className='me-3' type='submit'>Submit</Button>
                    </Form.Group>
                    <Form.Group>
                        <Button variant='secondary' onClick={handleClose}>Close</Button>
                    </Form.Group>
                </div>
            </Form>
        </Modal.Body>
    </Modal>;
}
