import React, {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import './_summary.scss';

import {VStore} from "../../store";
import puzzles from "../../utils/puzzles";
import {IContact} from "../../interface";
import {COUNTRY_LIST, PART_LIST} from "../../utils/constant";


export default function Summary() {
    const {isShowSummary, setIsShowSummary, shoe, textures, currentUser} = VStore();

    const [summary, setSummary] = useState<any>(null)

    const [image, setImage] = useState<string>('')
    const [imageBack, setImageBack] = useState<string>('')

    const [contact, setContact] = useState<IContact>({
        firstName: '',
        lastName: '',
        country: '',
        address: '',
        postalCode: '',
        city: '',
        email: '',
        phone: '',
        company: '',
        orderNotes: ''
    })

    const [sendStatus, setSendStatus] = useState<string>('');


    useEffect(() => {
        onShowSummary()
    }, [isShowSummary]);

    async function onShowSummary() {
        if (isShowSummary) {
            const _shoe = JSON.parse(JSON.stringify(shoe));
            if (_shoe.left.logo_quarter.text === '') {
                _shoe.left.logo_quarter.color = '';
                _shoe.left.logo_quarter.font = '';
            }
            if (_shoe.right.logo_quarter.text === '') {
                _shoe.right.logo_quarter.color = '';
                _shoe.right.logo_quarter.font = '';
            }
            puzzles.procedures?.lookFromBack();
            await new Promise(resolve => setTimeout(resolve, 100));
            const _imageBack = puzzles.procedures?.takeScreenshot();
            setImageBack(_imageBack!)
            puzzles.procedures?.lookFromFront();
            await new Promise(resolve => setTimeout(resolve, 100));
            const _image = puzzles.procedures?.takeScreenshot();
            setImage(_image!)
            const data = PART_LIST.map(part => {
                return {
                    part,
                    // @ts-ignore
                    left: textures[_shoe.left[part]]?.value,
                    // @ts-ignore
                    right: textures[_shoe.right[part]]?.value
                }
            })
            const customData = {
                'logo_strap': {left: _shoe.left['logo_strap'], right: _shoe.right['logo_strap']},
                'logo_quarter': {left: _shoe.left['logo_quarter'], right: _shoe.right['logo_quarter']},
                'logo_backtop': {left: _shoe.left['logo_backtop'], right: _shoe.right['logo_backtop']}
            }
            setSummary({data, customData})
            setContact({
                firstName: currentUser?.firstName || '',
                lastName: currentUser?.lastName || '',
                country: currentUser?.country || '',
                address: currentUser?.address || '',
                postalCode: currentUser?.zipCode || '',
                city: currentUser?.city || '',
                email: currentUser?.email || '',
                phone: currentUser?.phoneNumber || '',
                company: currentUser?.company || '',
                orderNotes: ''
            })
        } else {
            setContact({
                firstName: '',
                lastName: '',
                country: '',
                address: '',
                postalCode: '',
                city: '',
                email: '',
                phone: '',
                company: '',
                orderNotes: ''
            })
            setSummary(null)
        }
    }


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setSendStatus('sending');
        const _shoe = JSON.parse(JSON.stringify(shoe));
        if (_shoe.left.logo_quarter.text === '') {
            _shoe.left.logo_quarter.color = '';
            _shoe.left.logo_quarter.font = '';
        }
        if (_shoe.right.logo_quarter.text === '') {
            _shoe.right.logo_quarter.color = '';
            _shoe.right.logo_quarter.font = '';
        }
        PART_LIST.forEach((part) => {
            // @ts-ignore
            _shoe.left[part] = textures[_shoe.left[part]]?.name || textures[_shoe.left[part]]?.value || textures[_shoe.left[part]];
            //@ts-ignore
            _shoe.right[part] = textures[_shoe.right[part]]?.name || textures[_shoe.right[part]]?.value || textures[_shoe.right[part]];
        })
        const fetchOptions = {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json', // Specify the content type as JSON
            },
            body: JSON.stringify({
                shoeData: _shoe,
                contact: contact,
                image: image,
                imageBack: imageBack
            })
        };

        fetch('https://us-central1-citirun-a04f2.cloudfunctions.net/sendEmail', fetchOptions)
        /*fetch('http://localhost:5000/citirun-a04f2/us-central1/sendEmail', fetchOptions)*/
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setSendStatus('success');
            })
            .catch(error => {
                console.log(error)
                setSendStatus('error');
            });
    };

    const handleClose = useCallback(() => {
        setIsShowSummary(false);
        setSendStatus('');
    }, [setIsShowSummary]);

    return (
        <Modal size='xl' className='summary p-0' show={isShowSummary} onHide={handleClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                {
                    summary ?
                        <div className='d-flex flex-column flex-sm-row'>
                            <div className='col-12 col-sm-6 pe-sm-3 pe-0 d-flex flex-column'>
                                <h3>Order Details</h3>
                                <div className='select-product flex-grow-1'>
                                    <img src={image} alt="Product"/>
                                </div>
                                <div className='d-flex justify-content-evenly pb-3'>
                                    <div>
                                        <span>Type: </span>
                                        <b>Inline</b>
                                    </div>
                                    <div>
                                        <span>Size: </span>
                                        <b>{shoe.size}</b>
                                    </div>
                                    <div>
                                        <span>Mount: </span>
                                        <b>{shoe.mount}</b>
                                    </div>
                                </div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Left</th>
                                        <th>Right</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        summary.data.map((data: any) => {
                                            return <tr key={data.part + '_summary'}>
                                                <td className='text-capitalize'>
                                                    <b>{data.part.replace('_', ' ')}</b>
                                                </td>
                                                <td></td>
                                                <td>
                                                    {data.left}
                                                </td>
                                                <td>
                                                    {data.right}
                                                </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <td>
                                            <b>Strap Logo</b>
                                        </td>
                                        <td>
                                            <i>Color 1</i>
                                        </td>
                                        <td>{summary.customData.logo_strap.left || ''}</td>
                                        <td>{summary.customData.logo_strap.right || ''}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>
                                            <b>Quarter Logo</b>
                                        </td>
                                        <td>
                                            <i>Text</i>
                                        </td>
                                        <td>{summary.customData.logo_quarter.left.text || ''}</td>
                                        <td>{summary.customData.logo_quarter.right.text || ''}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i>Color</i>
                                        </td>
                                        <td>{summary.customData.logo_quarter.left.color || ''}</td>
                                        <td>{summary.customData.logo_quarter.right.color || ''}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i>Font</i>
                                        </td>
                                        <td>{summary.customData.logo_quarter.left.font || ''}</td>
                                        <td>{summary.customData.logo_quarter.right.font || ''}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Backtop Logo</b>
                                        </td>
                                        <td>
                                            <i>Color</i>
                                        </td>
                                        <td>{summary.customData.logo_backtop.left || ''}</td>
                                        <td>{summary.customData.logo_backtop.right || ''}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Form className='col-12 col-sm-6 ps-sm-3 ps-0 mt-sm-3' onSubmit={handleSubmit}>
                                <h3>Contact</h3>
                                <div className='d-flex'>
                                    <Form.Group className='col-6 pe-3 required'>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" required
                                                      value={contact.firstName}
                                                      onChange={(e) => setContact({
                                                          ...contact,
                                                          firstName: e.target.value
                                                      })}
                                                      disabled={sendStatus === 'sending'}
                                        />
                                    </Form.Group>
                                    <Form.Group className='col-6 ps-3 required' aria-required={true}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" required
                                                      value={contact.lastName}
                                                      onChange={(e) => setContact({
                                                          ...contact,
                                                          lastName: e.target.value
                                                      })}
                                                      disabled={sendStatus === 'sending'}
                                        />
                                    </Form.Group>
                                </div>
                                <Form.Group>
                                    <Form.Label>Company Name (optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Company Name"
                                                  value={contact.company}
                                                  onChange={(e) => setContact({...contact, company: e.target.value})}
                                                  disabled={sendStatus === 'sending'}
                                    />
                                </Form.Group>
                                <Form.Group className='required'>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => setContact({...contact, country: e.target.value})}
                                        value={contact.country}
                                        disabled={sendStatus === 'sending'}
                                    >
                                        {
                                            COUNTRY_LIST.map((country) => {
                                                return <option key={country.name}
                                                               value={country.name}>{country.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className='required'>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" placeholder="City"
                                                  value={contact.city}
                                                  onChange={(e) => setContact({...contact, city: e.target.value})}
                                                  disabled={sendStatus === 'sending'}
                                    />
                                </Form.Group>
                                <Form.Group className='required'>
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" placeholder="Home number and street name" required
                                                  value={contact.address}
                                                  onChange={(e) => setContact({...contact, address: e.target.value})}
                                                  disabled={sendStatus === 'sending'}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Postcode / Zip (optional)</Form.Label>
                                    <Form.Control type="text" placeholder="Postcode / Zip"
                                                  value={contact.postalCode}
                                                  onChange={(e) => setContact({...contact, postalCode: e.target.value})}
                                                  disabled={sendStatus === 'sending'}
                                    />
                                </Form.Group>

                                <Form.Group className='required'>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" placeholder="Phone" required
                                                  value={contact.phone}
                                                  onChange={(e) => setContact({...contact, phone: e.target.value})}
                                    />
                                </Form.Group>
                                <Form.Group className='required'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" required
                                                  value={contact.email}
                                                  onChange={(e) => setContact({...contact, email: e.target.value})}
                                                  disabled={sendStatus === 'sending'}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Order notes (optional)</Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                                  placeholder="Notes about your order, e.g. special notes for delivery."
                                                  value={contact.orderNotes}
                                                  onChange={(e) => setContact({
                                                      ...contact,
                                                      orderNotes: e.target.value
                                                  })}
                                                  disabled={sendStatus === 'sending'}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group className='d-flex justify-content-end mt-3'>
                                    <Button className='submit-btn' type='submit' style={{minWidth: '150px'}}
                                            disabled={sendStatus === 'sending' || sendStatus === 'success'}
                                            variant={sendStatus === 'success' ? 'success' : 'primary'}
                                    >
                                        {
                                            sendStatus === 'sending' ? ('Sending') : sendStatus === 'success' ? 'Sent' : 'Submit'
                                        }
                                        {
                                            sendStatus === 'sending' ? <Spinner animation="border" size="sm"
                                                                                className='ms-2'/> : sendStatus === 'success' ?
                                                <img src='./assets/images/icons/success.svg' className="ms-2"
                                                     alt='success'/> : null
                                        }
                                    </Button>
                                    {
                                        sendStatus === 'success' ?
                                            <Button variant='secondary' onClick={handleClose}
                                                    className='ms-3'>Close</Button>
                                            : null
                                    }

                                </Form.Group>
                            </Form>
                        </div>
                        : null
                }
            </Modal.Body>
        </Modal>);
}
