import React, {useEffect, useState} from 'react';
import ColorPalette from "../color-palette/color-palette";
import './_logo-strap.scss';
import {VStore} from "../../../../store";
import puzzles from "../../../../utils/puzzles";
export default function LogoStrap() {
    const {shoe, setShoe, currentSide, currentPart} = VStore();


    const [logoStrap, setLogoStrap] = useState<string>('');

    useEffect(() => {
        if (currentSide !== 'pair' && currentPart === 'logo_strap') {
            setLogoStrap(shoe[currentSide].logo_strap);
        }
    }, [currentPart, currentSide])

    useEffect(() => {
        const _shoe = JSON.parse(JSON.stringify(shoe));
        _shoe[currentSide].logo_strap = logoStrap;
        setShoe(_shoe);

        puzzles.procedures?.changeStrapLogoColor(logoStrap, currentSide);
    }, [logoStrap])

    return (<div className="logo-strap w-100 d-flex flex-column">
        <ColorPalette _currentValue={logoStrap}
                      onSelect={(color) => {
                          setLogoStrap(color);
                      }}
        />
    </div>);
}
