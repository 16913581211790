interface IProcedures {
    setGlossyLeather: (target: string, color: string) => void;
    setMetallicLeather: (target: string, color: string) => void;
    setMattLeather: (target: string, color: string) => void;
    replaceImage: (url: string, parent: string) => void;
    setCurrentObject: (target: string, from3D: boolean) => void;
    applyCustom: (target: string, urls: {
        base: string,
        met: string,
        roughness: string,
        normal: string
    }) => void;
    toggleLeft: (isShow: boolean) => void;
    toggleRight: (isShow: boolean) => void;
    showPair: () => void;
    takeScreenshot: () => string;
    lookFromFront: () => void;
    lookFromBack: () => void;
    changeCustomName: (name: string, font: string, side: string) => void;
    changeCustomNameColor: (color: string, side: string) => void;
    changeStrapLogoColor: (color: string, side: string) => void;
    changeBacktopLogoColor: (color: string, side: string) => void;
    switchToCrazyLab: () => void;
}


class Puzzles {
    public procedures: IProcedures | null = null;
    public setProcedures(procedures: any) {
        this.procedures = procedures;
    }
}

const puzzles = new Puzzles();
export default puzzles;
