import React, {useEffect} from 'react';
import ColorPalette from "../color-palette/color-palette";
import {VStore} from "../../../../store";
import {Form} from "react-bootstrap";
import puzzles from "../../../../utils/puzzles";

export default function LogoQuarter() {
    const {currentSide, currentPart, shoe, setShoe} = VStore();

    const [logoQuarterValue, setLogoQuarterValue] = React.useState<{ text: string, color: string, font: string }>({
        text: '',
        color: '',
        font: ''
    });

    useEffect(() => {
        if (currentPart === 'logo_quarter' && currentSide !== 'pair') {
            const logoQuarter = {...shoe[currentSide].logo_quarter};
            setLogoQuarterValue(logoQuarter);
        }
    }, [currentPart, currentSide]);

    useEffect(() => {
        const _shoe = JSON.parse(JSON.stringify(shoe));
        _shoe[currentSide].logo_quarter = logoQuarterValue;
        setShoe(_shoe);
        puzzles.procedures?.changeCustomNameColor(logoQuarterValue.color, currentSide)
    }, [logoQuarterValue.color])

    useEffect(() => {
        const _shoe = JSON.parse(JSON.stringify(shoe));
        _shoe[currentSide].logo_quarter = logoQuarterValue;
        setShoe(_shoe);
        puzzles.procedures?.changeCustomName(logoQuarterValue.text, logoQuarterValue.font, currentSide)
    }, [logoQuarterValue.font, logoQuarterValue.text])


    return (<div className="logo-strap w-100 d-flex flex-column">
        {
            currentSide !== 'pair' ? <>
                <div className='w-100 d-flex align-items-center'>
                    <span className='logo-strap__name'>CUSTOM TEXT</span>
                    <hr/>
                </div>
                <div className='d-flex'>
                    <Form.Control type='text' placeholder='Enter text'
                                  value={logoQuarterValue.text}
                                  onChange={(e) => {
                                      if (e.target.value.length <= 10) {
                                          setLogoQuarterValue({...logoQuarterValue, text: e.target.value})
                                      }
                                  }}
                    ></Form.Control>
                </div>
                <div className='w-100 d-flex align-items-center'>
                    <span className='logo-strap__name'>COLOR</span>
                    <hr/>
                </div>
                <ColorPalette _currentValue={logoQuarterValue.color}
                              onSelect={(color) => {
                                  setLogoQuarterValue({...logoQuarterValue, color: color});
                              }}
                />
                <div className='w-100 d-flex align-items-center'>
                    <span className='logo-strap__name'>FONT</span>
                    <hr/>
                </div>
                <div className='d-flex'>
                    <Form.Select
                        value={logoQuarterValue.font}
                        onChange={(e) => setLogoQuarterValue({...logoQuarterValue, font: e.target.value})}
                    >
                        <option style={{fontFamily: "'Times New Roman', Times, serif"}} value='bahnschrift'>
                            Bahnschrift
                        </option>
                        <option value='bookantiqua'>Bookantiqua</option>
                        <option value='calibri'>Calibri</option>
                        <option value='comic'>Comic</option>
                        <option value='consola'>Consola</option>
                        <option value='corbel'>Corbel</option>
                        <option value='ebrima'>Ebrima</option>
                        <option value='gabriola'>Gabriola</option>
                        <option value='georgia'>Georgia</option>
                        <option value='gothic'>Gothic</option>
                        <option value='impact'>Impact</option>
                        <option value='leelaw'>Leelaw</option>
                        <option value='mistral'>Mistral</option>
                        <option value='monotypecorsiva'>Monotypecorsiva</option>
                        <option value='segoepr'>Segoepr</option>
                        <option value='sserife'>Sserife</option>
                        <option value='tahoma'>Tahoma</option>
                        <option value='times'>Times</option>
                        <option value='butter'>Butter Notes</option>
                    </Form.Select>
                </div>
            </> : null
        }
    </div>);
}
