import {Country} from "country-state-city";

const PART_LIST = [
    'back_top',
    'decoration_tongue',
    'quarter1',
    'quarter2',
    'quarter3',
    'strap',
    'tongue'
]

const CUSTOM_LIST = [
    'logo_strap',
    'logo_quarter',
    'logo_backtop',
]

const SIZE = [
    '190',
    '195',
    '200',
    '205',
    '210',
    '215',
    '220',
    '225',
    '230',
    '235',
    '240',
    '245',
    '250',
    '255',
    '260',
    '265',
    '270',
    '275',
    '280',
    '285',
    '290',
    '295',
    '300',
    'Custom'
]

const COUNTRY_LIST = Country.getAllCountries();

export {PART_LIST, CUSTOM_LIST, COUNTRY_LIST, SIZE}
