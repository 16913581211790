import {IShoePart, ITexture} from "../../../../interface";
import './_option-buttons.scss';
import {VStore} from "../../../../store";
import Puzzles from "../../../../utils/puzzles";

interface IOptionButtonProps {
    part: IShoePart;
    name: string;
    texturesIds: string[];
}

export const OptionImages = ({part, name, texturesIds}: IOptionButtonProps) => {
    const {shoe, setShoe, currentSide, textures, materials} = VStore();

    // @ts-ignore
    const shoeData = shoe[currentSide];

    const filteredTextures = texturesIds ? texturesIds.map((id) => {
        return textures[id]
    }) : [];

    function selectOption(texture: ITexture) {
        const _shoe = {...shoe};
        // @ts-ignore
        _shoe[currentSide][part] = texture.id;

        setShoe(_shoe);

        const materialData = materials[texture.materialId];

        Puzzles.procedures?.applyCustom(part, {
            base: texture.image || '',
            normal: materialData.normal || '',
            met: materialData.metallic || '',
            roughness: materialData.roughness || ''
        });
    }

    return <div className='option-buttons w-100 d-flex flex-column'>
        <div className='w-100 d-flex align-items-center'>
            <span className='option-button__name'>{name}</span>
            <hr/>
        </div>
        <div className='w-100 d-flex flex-nowrap flex-md-wrap overflow-auto' style={{maxHeight: '250px'}}>
            {
                filteredTextures.map((texture, index) => {
                    return <button
                        key={texture.id}
                        className={shoeData[part] === texture.id ? 'active' : ''}
                        onClick={() => selectOption(texture)}
                    >
                        <img src={texture.thumbnail} alt={texture.id}/>
                    </button>
                })
            }
        </div>
    </div>
}
