import {create} from 'zustand'
import {IData, IMaterials, IShoe, IShoePart, ITextures, IUser} from "../interface";


type VStoreType = {
    currentPart: IShoePart,
    data: IData,
    textures: ITextures,
    materials: IMaterials,
    shoe: IShoe,
    currentSide: 'left' | 'right' | 'pair',
    isFirstSwitch: boolean
    isShowSummary: boolean,
    shoeType: string,
    isShowLogin: boolean,
    isShowRegister: boolean,
    currentUser: IUser | null,

    setCurrentPart: (currentPart: IShoePart) => void,
    setData: (newData: { data: IData, textures: ITextures, materials: IMaterials }) => void,
    setShoe: (newShoe: IShoe) => void,
    setDefaultShoe: () => void,
    setCurrentSide: (newSide: 'left' | 'right' | 'pair') => void,
    setIsFirstSwitch: (isFirstSwitch: boolean) => void
    setIsShowSummary: (isShowSummary: boolean) => void
    setShoeType: (shoeType: string) => void
    setIsShowLogin: (isShowLogin: boolean) => void
    setIsShowRegister: (isShowRegister: boolean) => void
    setCurrentUser: (currentUser: IUser | null) => void
    resetData: () => void
}

const ShoeDataDefault = {
    'back_top': '77b1497a-465f-411e-aed6-444ccbf45737',
    'decoration_tongue': 'c1374bb4-9584-4696-bd7a-6009487b6f86',
    'quarter1': '6ecd23b2-041e-4518-8c51-aae2205d4174',
    'quarter2': '48ebf372-a525-4935-bb44-26a2b3667423',
    'quarter3': '48ebf372-a525-4935-bb44-26a2b3667423',
    'strap': '77b1497a-465f-411e-aed6-444ccbf45737',
    'tongue': '77b1497a-465f-411e-aed6-444ccbf45737',
    'logo_strap': 'rgb(255, 255, 255)',
    'logo_quarter': {
        text: '',
        color: 'rgb(255, 255, 230)',
        font: 'bahnschrift'
    },
    "logo_backtop": 'rgb(255, 255, 230)'
}

const ShoeDefault = {
    left: ShoeDataDefault,
    right: ShoeDataDefault,
    size: '200',
    mount: '165',
    type: 'Inline'
}

function getShowType() {
    const url = window.location.origin;
    return url.includes('crazylab') ? 'crazy_lab' : 'ctr';
}

export const VStore = create<VStoreType>((set, get) => ({
    currentPart: 'quarter1',
    data: {},
    textures: {},
    materials: {},
    shoe: ShoeDefault,
    currentSide: 'right',
    isFirstSwitch: true,
    isShowSummary: false,
    shoeType: getShowType(),
    isShowLogin: false,
    isShowRegister: false,
    currentUser: null,

    setCurrentPart: (currentPart: IShoePart) => set({currentPart}),
    setData: (newData) => set({data: newData.data, textures: newData.textures, materials: newData.materials}),
    setShoe: (newShoe: IShoe) => {
        set({shoe: newShoe})
    },
    setDefaultShoe: () => {
        set({
            currentPart: 'quarter1',
            shoe: ShoeDefault,
            currentSide: 'right',
            isFirstSwitch: true,
            isShowSummary: false,
        })
    },
    setCurrentSide: (newSide: 'left' | 'right' | 'pair') => set({currentSide: newSide}),
    setIsFirstSwitch: (isFirstSwitch: boolean) => set({isFirstSwitch: isFirstSwitch}),
    setIsShowSummary: (isShowSummary: boolean) => set({isShowSummary: isShowSummary}),
    setShoeType: (shoeType: string) => set({shoeType}),
    setIsShowLogin: (isShowLogin: boolean) => set({isShowLogin: isShowLogin}),
    setIsShowRegister: (isShowRegister: boolean) => set({isShowRegister: isShowRegister}),
    setCurrentUser: (currentUser: IUser | null) => set({currentUser}),
    resetData: () => {
        set({
            currentPart: 'quarter1',
            shoe: ShoeDefault,
            currentSide: 'right',
            isFirstSwitch: true,
        })
    }
}))


