import {initializeApp} from "firebase/app";
import {getDatabase, onValue, ref, set, get} from "firebase/database";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDajR0JN_7R-U9rXp-ehpeyUB9iu8v8JD0",
    authDomain: "citirun-a04f2.firebaseapp.com",
    databaseURL: "https://citirun-a04f2-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "citirun-a04f2",
    storageBucket: "citirun-a04f2.appspot.com",
    messagingSenderId: "420073479932",
    appId: "1:420073479932:web:c542554075918205d68179"
};

class FirebaseSingleton {
    private readonly _app;
    private readonly _database;
    private readonly _dataBaseRef;
    private readonly _auth;

    constructor() {
        this._app = initializeApp(firebaseConfig);
        this._database = getDatabase(this._app);
        this._dataBaseRef = ref(this._database, '/');
        this._auth = getAuth(this._app);
    }

    public refData(callBack: (val: any) => void): void {
        onValue(this._dataBaseRef, (snapshot) => {
            if (snapshot.exists()) {
                callBack(snapshot.val());
            }
        });
    }

    public async createUser(email: string, password: string): Promise<any> {
        try {
            return await createUserWithEmailAndPassword(this._auth, email, password);
        } catch (e) {
            throw e;
        }
    }

    public async signIn(email: string, password: string): Promise<any> {
        try {
            return await signInWithEmailAndPassword(this._auth, email, password);
        } catch (e) {
            throw e;
        }
    }

    public async signOut(): Promise<any> {
        try {
            return await this._auth.signOut();
        } catch (e) {
            return e;
        }
    }

    public async setUserData(uid: string, data: any): Promise<any> {
        try {
            return await set(ref(this._database, `users/${uid}`), data);
        } catch (e) {
            return e;
        }
    }

    public async getUserData(uid: string): Promise<any> {
        try {
            const snapshot = await get(ref(this._database, `users/${uid}`));
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                return null;
            }
        } catch (e) {
            return e;
        }
    }

    public async onAuthStateChanged(callBack: (user: any) => void): Promise<any> {
        return this._auth.onAuthStateChanged(callBack);
    }
}

export const FirebaseUtils = new FirebaseSingleton();
