import {OptionColors} from "./option-colors/option-colors";
import {OptionUpload} from "./option-upload/option-upload";
import {OptionImages} from "./option-images/option-images";
import CustomList from "../custom-list/custom-list";
import './_options-list.scss';
import {VStore} from "../../../store";
import {IMaterialList, IShoePart} from "../../../interface";

export const OptionList = () => {
    const {data, currentPart, materials} = VStore();

    const switchMaterial = (material: IMaterialList, part: IShoePart) => {
        const materialData = materials[material.id];
        switch (materialData.type) {
            case 'mirror':
                return <OptionColors name={materialData.name}
                                     textureIds={material.textureIds}
                                     part={part}
                />
            case 'metallic':
                return <OptionColors name={materialData.name}
                                     textureIds={material.textureIds}
                                     part={part}
                />
            case 'matt':
                return <OptionColors name={materialData.name}
                                     textureIds={material.textureIds}
                                     part={part}
                />
            case 'custom-image':
                return <OptionImages
                    part={part}
                    name={materialData.name}
                    texturesIds={material.textureIds}
                />
            default:
                return <OptionUpload part={part}/>
        }
    }

    return <div className='w-100 h-100'>
        {
            Object.values(data).map((part, index) => {
                return <div className={'options flex-column ' + (currentPart !== part.target ? 'd-none' : 'd-flex')}
                            key={part.name}>
                    <span className='options__name'>{part.name}</span>
                    {
                        part.materials.map((material, index) => {
                            return (
                                <div key={material.id}>
                                    {switchMaterial(material, part.target)}
                                </div>
                            )
                        })
                    }
                    {/*<div>
                        <OptionCustom parent={item.target}/>
                    </div>*/}
                </div>
            })
        }
        <CustomList/>
    </div>
}
