import './_option-upload.scss';
import {Form} from "react-bootstrap";
import React, {useRef} from "react";
import Puzzles from "../../../../utils/puzzles";
import {useState} from "react";
import {IShoePart} from "../../../../interface";

interface IOptionUploadProps {
    part: IShoePart;
}
export const OptionUpload = ({part}: IOptionUploadProps) => {
    const [fileUrl, setFileUrl] = useState<string>('');

    const fileRef = useRef(null);

    function clearFile() {
        Puzzles.procedures?.replaceImage('', part);
        setFileUrl('');
        if (fileRef.current) {
            (fileRef.current as any).value = '';
        }
    }
    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files
        if (fileList && fileList.length > 0) {
            const file = fileList[0];
            const url = URL.createObjectURL(file)
            Puzzles.procedures?.replaceImage(url, part);
            setFileUrl(url);
        }
    }

    return <div className='option-upload w-100 d-flex flex-column'>
        <div className='w-100 d-flex align-items-center'>
            <span className='option-upload__name'>Custom</span>
            <hr/>
        </div>
        <div className='w-100'>
            <Form>
                <Form.Control ref={fileRef} type="file" accept=".png,.webp" onChange={handleFileChange} multiple={false}/>
            </Form>
            {
                fileUrl ?
                <div className='img-thumbnail mt-3'>
                    <img src={fileUrl} alt='custom'/>
                    <button className='remove-btn' onClick={clearFile}>&times;</button>
                </div> : ''
            }
        </div>
    </div>
}
