import React from 'react';
import {VStore} from "../../../store";
import LogoStrap from "./logo-strap/logo-strap";
import LogoQuarter from "./logo-quarter/logo-quarter";
import LogoBacktop from "./logo-backtop/logo-backtop";
import {CUSTOM_LIST} from "../../../utils/constant";

export default function CustomList() {
    const {currentPart} = VStore();


    return (<>
        {
            CUSTOM_LIST.map((custom) => (
                <div key={custom} className={'options flex-column mb-3 ' + (currentPart !== custom ? 'd-none' : 'd-flex')}>
                <span className='options__name'>
                    {currentPart.replace('_', ' ')}
                </span>
                    <div>
                        {
                            currentPart === 'logo_strap' ?
                                <LogoStrap/> : currentPart === 'logo_quarter' ?
                                    <LogoQuarter/> : currentPart === 'logo_backtop' ?
                                        <LogoBacktop/> : null
                        }
                    </div>
                </div>
            ))
        }
    </>)
}
