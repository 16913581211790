import React, {useEffect} from 'react';
import {Controller, Summary, V3DApp} from "../../compenents";
import {VStore} from "../../store";

export default function Customize() {
    const {resetData, currentUser, setIsShowLogin} = VStore();

    useEffect(() => {
        if (!currentUser) {
            setTimeout(() => {
                setIsShowLogin(true);
            }, 100);
        }

        return () => {
            resetData();
        }
    }, []);

    return (<div className='h-100 w-100 d-flex flex-column flex-sm-row-reverse'>
        <V3DApp/>
        <Controller/>
        <Summary/>
    </div>);
}
